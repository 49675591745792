import React, { useRef, useState } from "react";
import { address, email, mobile, socialMedia, emailJs } from "../constants";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Contact = () => {
  const form = useRef();
  // var map;
  // function initMap() {
  //   map = new google.maps.Map(document.getElementById("map"), {
  //     center: { lat: -34.397, lng: 150.644 },
  //     zoom: 8,
  //   });
  // }
  // window.initMap = initMap;
  const [from_name, setName] = useState("");
  const [from_email, setEmail] = useState("");
  const [from_mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();
    // let payload = {
    //   from_name: from_name,
    //   from_email: from_email,
    //   message: from_subject,
    //   from_mobile: from_mobile,
    // };
    emailjs
      .sendForm(
        emailJs.service_id,
        emailJs.template_id,
        form.current,
        emailJs.publicKey
      )
      .then(
        (result) => {
          setName("");
          setEmail("");
          setMobile("");
          setMessage("");
          swal(
            "Your Response Has Captured. Our Representative will get in touch with you ASAP"
          );
        },
        (error) => {
          swal("Oops!", "Something went wrong!", "error");
        }
      );
  };

  return (
    <div>
      <Helmet>
        <title>ProLeap Systems || Contact Us</title>
      </Helmet>
      <section
        className="hero-wrap hero-wrap-2"
        style={{
          backgroundImage: "url(images/xbg_3.jpg.pagespeed.ic.M6GN2K7j8o.jpg)",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end justify-content-center">
            <div className="col-md-9 text-center mb-5">
              <p className="breadcrumbs">
                <span className="me-2">
                  <Link to="/">
                    Home <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>
                <span>
                  Contact us <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 className="mb-0 bread">Contact us</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="ftco-section bg-light">
        <div className="container-xl">
          <div className="row no-gutters justify-content-center">
            <div className="col-lg-10">
              <div className="wrapper">
                <div className="row g-0">
                  <div className="col-lg-12">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3>Contact us</h3>
                      <p className="mb-4">
                        have you have any Query, Please feel free to write to us
                      </p>
                      <div className="row mb-4">
                        <div className="col-md-4">
                          <div className="dbox w-100 d-flex align-items-start">
                            <div className="text">
                              <p>
                                <span>Address:</span> {address.line1},{" "}
                                {address.city}, {address.state}, USA,{" "}
                                {address.pincode}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="dbox w-100 d-flex align-items-start">
                            <div className="text">
                              <p>
                                <span>Email:</span>
                                {email}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="dbox w-100 d-flex align-items-start">
                            <div className="text">
                              <p>
                                <span>Phone:</span> {mobile}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form
                        id="contactForm"
                        name="contactForm"
                        className="contactForm"
                        ref={form}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="from_name"
                                id="from_name"
                                value={from_name}
                                placeholder="Name"
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="from_email"
                                id="from_email"
                                value={from_email}
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="from_mobile"
                                id="from_mobile"
                                value={from_mobile}
                                placeholder="Mobile"
                                onChange={(e) => setMobile(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="message"
                                id="message"
                                value={message}
                                cols="30"
                                rows="7"
                                placeholder="Create a message here"
                                onChange={(e) => setMessage(e.target.value)}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="button"
                                value="Send Message"
                                className="btn btn-primary"
                                onClick={(e) => sendEmail(e)}
                              />
                              <div className="submitting"></div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="w-100 social-media mt-5">
                        <h3>Follow us here</h3>
                        <ul className="ftco-footer-social mt-4">
                          <li className="mx-4">
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={socialMedia.twitter}
                            >
                              <span className="fa fa-twitter"></span>
                            </a>
                          </li>
                          <li className="mx-4">
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={socialMedia.facebook}
                            >
                              <span className="fa fa-facebook"></span>
                            </a>
                          </li>
                          <li className="mx-4">
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={socialMedia.googlePlus}
                            >
                              <span className="fa fa-google"></span>
                            </a>
                          </li>
                          <li className="mx-4">
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={socialMedia.instagram}
                            >
                              <span className="fa fa-instagram"></span>
                            </a>
                          </li>
                          <li className="mx-4">
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={socialMedia.linkedIn}
                            >
                              <span className="fa fa-linkedin"></span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex align-items-stretch">
                    <div id="map" className="bg-white"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
