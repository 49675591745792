export const email = "info@proleapsystems.com";
export const mobile = "+1(510)737-8285";
export const address = {
  line1: "980 Henderson ave Apt 1",
  line2: "",
  city: "Sunnyvale",
  state: "California",
  pincode: "94086",
  geoLocation: {
    latitude: "",
    longitude: "",
  },
};
export const socialMedia = {
  facebook: "",
  instagram: "",
  twitter: "",
  googlePlus: "",
  linkedIn: "",
};
export const services = [
  "IT Consulting",
  "Mobile Application Development",
  "Software Development",
  "Web/UI Development",
  "Quality Assurance",
  "Network Management",
  "DevOps",
];
export const servicesLinks = [
  "itconsulting",
  "MAD",
  "SD",
  "webui",
  "QA",
  "network",
  "devops",
];

export const emailJs = {
  service_name: "Gmail",
  service_id: "service_le3vqeg",
  tempalte_name: "Contact Form",
  template_id: "template_bge9e2q",
  publicKey: "HGlHRjgb4rermNJBE",
  email: "info@proleapsystems.com",
};
