import React from "react";
// import { Link } from 'react-router-dom';
import { address, mobile, socialMedia } from "../constants";

export const TopHeader = () => {
  return (
    <div className="top-wrap">
      <div className="container">
        <div className="row">
          <div className="col-md-3 d-flex align-items-center mb-2 mb-md-0">
            <div className="con">
              <p className="mb-0">
                <span className="fa fa-phone"></span> Call Us: {mobile}
              </p>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center mb-2 mb-md-0">
            <div className="con">
              <p className="mb-0">
                <span className="fa fa-map-o"></span> Location: {address.line1},{" "}
                {address.city}, {address.state}, USA, {address.pincode}{" "}
              </p>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-start justify-content-md-end align-items-center">
            <p className="ftco-social d-flex">
              <a
                rel="noreferrer"
                target="_blank"
                href={socialMedia.twitter}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-twitter"></span>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href={socialMedia.facebook}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-facebook"></span>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href={socialMedia.googlePlus}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-google"></span>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href={socialMedia.instagram}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-instagram"></span>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href={socialMedia.linkedIn}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-linkedin"></span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
